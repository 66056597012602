import { withUseQuery } from '@wision/api';
import type { QueryFunctionContext, QueryClient } from '@tanstack/vue-query';
import { type Ref, nextTick } from 'vue';
import { type DataResponse } from '@wision/api';

type Data = DataResponse[0]['data'][number];

const MOBILE_MAX_WIDTH = 930; //Max width to apply mobile designs for mobile & tablet

export type Language = 'en' | 'sv' | 'de' | 'da';

export const getContentMaxHeight = () => {
  const headerHeight = document.getElementById('page-header')?.getBoundingClientRect().height || 0;
  const filterHeight = document.getElementById('page-filter')?.getBoundingClientRect().height || 0;
  const profileHeadHeight = document.getElementById('profile-head')?.getBoundingClientRect().height || 0;
  const tabsHeadHeight = document.getElementById('tabs-head')?.getBoundingClientRect().height || 0;
  const sidebarBackdrop = document.getElementById('sidebar-backdrop');
  const sidebarContent = document.getElementById('sidebar-content');
  const main = document.getElementById('main');
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  if (isIOS) {
    const body = document.querySelector('body');
    const scrollPosition = window.pageYOffset;
    if (body) {
      body.style.overflow = 'hidden';
      body.style.position = 'fixed';
      body.style.top = `-${scrollPosition}px`;
      body.style.width = '100%';
    }
  }
  if (sidebarContent && sidebarBackdrop) {
    sidebarContent.style.removeProperty('min-height');
    sidebarContent.style.removeProperty('max-height');
    if (window.innerHeight > 350) {
      sidebarContent.style.maxHeight = window.innerHeight + 'px';
      sidebarBackdrop.style.maxHeight = window.innerHeight + 'px';
    } else {
      sidebarContent.style.minHeight = sidebarContent.scrollHeight + 'px';
      sidebarBackdrop.style.minHeight = sidebarContent.scrollHeight + 'px';
    }
    if (main) {
      main.style.minHeight = sidebarContent.scrollHeight + 'px';
    }
    if (sidebarContent.scrollHeight > document.documentElement.clientHeight) {
      return sidebarContent.scrollHeight - headerHeight - filterHeight - 10;
    }
  }
  if (profileHeadHeight && tabsHeadHeight) {
    const totalProfileHeight = profileHeadHeight + tabsHeadHeight;
    return document.documentElement.clientHeight - headerHeight - filterHeight - totalProfileHeight - 10;
  }
  return document.documentElement.clientHeight - headerHeight - filterHeight - 10;
};

export const checkIsMobile = () => {
  return window.innerWidth < MOBILE_MAX_WIDTH;
};

export const onSwupEvent = (callBack: () => void) => {
  document.addEventListener('swup:contentReplaced', () => {
    callBack();
  });
};

export const withQueryCancellation = <T>(
  client: QueryClient,
  enabled: Ref<boolean>,
  apiFn: () => Promise<[T, boolean, (string | undefined)?]>
) => async (context: QueryFunctionContext): Promise<T | undefined> => {
    await nextTick();

    if (!enabled.value) {
      client.cancelQueries(context.queryKey);
      return;
    }

    return withUseQuery(apiFn);
  };

/**
 * Convert sensor view value to value
 */
export const convertValue = (
  data: Data | undefined
) =>
  data
    ? {
      name: data.name || '',
      unit: data.unit || '',
      value: data.points[0].y
    }
    : undefined;
